import MmenuLight from "mmenu-light";

export default () => {
  const menu = new MmenuLight(
    document.querySelector("#mobile-menu"),
    "(max-width: 1024px)"
  );

  const navigator = menu.navigation();
  const drawer = menu.offcanvas();

  // Open Menu
  const navTrigger = document.querySelector(".js-open-nav");
  const projectWrapper = document.getElementById("app");

  // Toggle Menu
  bindEvent(navTrigger, "click", () => {
    console.log("click");
    navTrigger.classList.toggle("is-active");
    projectWrapper.classList.toggle("js-is-open");

    if (navTrigger.classList.contains("is-active")) {
      drawer.open();
    } else {
      drawer.close();
    }
  });

  // Bind event
  function bindEvent(el, eventName, eventHandler) {
    if (el.addEventListener) {
      el.addEventListener(eventName, eventHandler, false);
    } else if (el.attachEvent) {
      el.attachEvent(`on${eventName}`, eventHandler);
    }
  }
};
