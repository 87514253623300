export default function () {
  const tables = document.querySelectorAll(".wysiwyg table");

  tables.forEach((item) => {
    // Create the outer wrapper with the 'table-wrapper' class
    const outerWrapper = document.createElement("div");
    outerWrapper.classList.add("table-wrapper");

    // Create the inner wrapper div
    const innerWrapper = document.createElement("div");

    // Insert the outer wrapper before the table
    item.parentNode.insertBefore(outerWrapper, item);

    // Append the inner wrapper to the outer wrapper
    outerWrapper.appendChild(innerWrapper);

    // Append the table to the inner wrapper
    innerWrapper.appendChild(item);
  });
}
