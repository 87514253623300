export default () => {
  const iFrame = document.querySelectorAll("figure iframe");
  if (iFrame) {
    iFrame.forEach((item) => {
      const wrapper = document.createElement("div");
      item.parentNode.insertBefore(wrapper, item);
      wrapper.appendChild(item);

      item.parentNode.classList.add("video-wrapper");
    });
  }
};
