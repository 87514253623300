import Swiper from "swiper";
import { Navigation } from "swiper/modules";

export default function () {
  const swiper = new Swiper(".tileSwiper", {
    modules: [Navigation],

    slidesPerView: 1,
    spaceBetween: 24,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 24,
      },

      1024: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
  });
}
