import setupAccordions from "./accordions";
import setupSwiper from "./tile-swiper";
import responsiveIframe from "./responsive-iframe";
import responsiveTables from "./responsive-tables";
import menu from "./menu";

setupAccordions();
setupSwiper();
responsiveIframe();
responsiveTables();
menu();
